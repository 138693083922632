import React, { useContext } from 'react'
import ClassNames from 'classnames'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Divider from 'Clutch/Atoms/Divider'
import Card from 'Clutch/Atoms/Card'
import Typography from 'Clutch/Atoms/Typography'
import { ProductPageContext } from '../../contexts/ProductPageContext';

import Description from './description/description'
import Specs from './specs/specs'
import Badges from './badges/badges'

import styles from './summary.module.scss'

const Summary = ({ }) => {
  const productPageContext = useContext(ProductPageContext);
  const displayingKeySpecs = productPageContext.specs && productPageContext.specs.length && productPageContext.specs.some(spec => spec.isKeySpec);
  const displayingProductDescription = productPageContext.subtitle || (productPageContext.bullets && productPageContext.bullets.length);

  return (
    <ErrorBoundary>
      {
        displayingProductDescription ?
          <div className={displayingKeySpecs ? styles.pdp_summary_desc : ClassNames(styles.pdp_summary_desc, styles.with_blank_section)}>
            <Card fill fillHeight noMargin>
              <Typography font={'bold'} tone={'contrast'} size={1.25} className={styles.pdp_summary_title} data-testid={'pdp-summary-details'}>
                Product Summary
              </Typography>
              <Divider />
              <Description toggleAccordion={productPageContext.toggleAccordion}
                isKit={productPageContext.isKit}
                showWhatsInTheKitDrawer={productPageContext.showWhatsInTheKitDrawer}
                subtitle={productPageContext.subtitle}
                bullets={productPageContext.bullets}
                hasRebates={(productPageContext.rebates || []).length > 0} />
            </Card>
          </div>
          :
          false
      }
      {
        displayingKeySpecs ?
          <div className={displayingProductDescription ? styles.pdp_summary_specs : ClassNames(styles.pdp_summary_specs, styles.with_blank_section, styles.leftmost_section)}>
            <Card fill fillHeight noMargin>
              <Typography font={'bold'} tone={'contrast'} size={1.25} className={styles.pdp_summary_title} data-testid={'pdp-summary-specs'}>
                Key Specs
              </Typography>
              <Divider />
              <Specs toggleAccordion={productPageContext.toggleAccordion} />
            </Card>
          </div>
          :
          false
      }

      <div className={displayingKeySpecs && displayingProductDescription ? styles.pdp_summary_badges
        : !displayingKeySpecs && !displayingProductDescription ? ClassNames(styles.pdp_summary_badges, styles.with_blank_section, styles.leftmost_section)
          : ClassNames(styles.pdp_summary_badges, styles.with_blank_section)}>
        <Card fill fillHeight noMargin>
          <Typography font={'bold'} tone={'contrast'} size={1.25} className={styles.pdp_summary_title} data-testid={'pdp-summary-badges'}>
            Need To Know
          </Typography>
          <Divider />
          <Badges
            isKit={productPageContext.isKit}
            showWhatsInTheKitDrawer={productPageContext.showWhatsInTheKitDrawer}
            shipsFree={productPageContext?.shippingEstimate?.freeShippingQuoteAvailable}
            canShipFree={productPageContext?.shippingEstimate?.canShipFree}
            isReturnable={productPageContext.isReturnable}
            isFreightShip={productPageContext.isFreightShip}
            isMadeToOrder={productPageContext.isMadeToOrder}
            isHazardous={productPageContext.isHazardous} 
            isShippedFullyEnclosedInCardboard={productPageContext.isShippedFullyEnclosedInCardboard}
            freeShippingThreshold={productPageContext.freeShippingThreshold}/>
        </Card>
      </div>
    </ErrorBoundary>
  )
}

export default Summary