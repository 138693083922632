import React, { useContext, useMemo } from 'react'

import { ProductPageContext } from '../../contexts/ProductPageContext'
import classNames from 'classnames'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Accordion from 'Clutch/Molecules/Accordion'
import Typography from 'Clutch/Atoms/Typography'
import Card from 'Clutch/Atoms/Card'
import Divider from 'Clutch/Atoms/Divider'

import Table from './table/table'

import styles from './specs.module.scss'


const Specs = ({ stickyHeaderRef }) => {

  const productPageContext = useContext(ProductPageContext)

  const keySpecs = useMemo(() => (productPageContext.specs ?? []).filter((item) => item.isKeySpec), [productPageContext.specs])
  const generalSpecs = useMemo(() => (productPageContext.specs ?? []).filter((item) => !item.isKeySpec), [productPageContext.specs])
  const indexOptions = useMemo(() => (productPageContext.indexOptions || []).map(indexOption => indexOption.displayName), [productPageContext.indexOptions])


  const specsAccordionSummary = (
    <Typography as={'h2'} size={1.25} font={'heading'}>
      {productPageContext.manufacturerPartNumber && productPageContext.brand && productPageContext.brand.brandName ?
        `${productPageContext.brand.brandName} ${productPageContext.manufacturerPartNumber} Specifications` : 'Specifications'}
    </Typography>
  )



  if (!productPageContext.specs || productPageContext.specs.length == 0) {
    return false;
  }

  return (
    <Card fill ref={stickyHeaderRef}>
      <Accordion
        intent={'action'}
        iconClosed={'plus'}
        iconOpened={'minus'}
        size={0.875}
        summary={specsAccordionSummary}
        fill
        segmentAccordionName={'pdp specs'}
        defaultOpen={productPageContext.isSpecsAccordionOpen}
        onChange={(isOpen) => productPageContext.toggleAccordion('Specs', isOpen)}>
        <ErrorBoundary>
          { /* The class "attribute-container" is to try to get google to better enhance our page cards */}
          <table className={classNames(styles.specs, "attribute-container")}>
            {keySpecs && keySpecs.length ?
              <ErrorBoundary>
                <thead>
                  <tr>
                    <Typography as={'th'} font={'heading'} className={styles.spec_heading}>
                      KEY SPECS
                    </Typography>
                  </tr>
                </thead>
                <Table specs={keySpecs} indexOptions={indexOptions} />

              </ErrorBoundary> : false}

            {keySpecs && keySpecs.length && generalSpecs && generalSpecs.length ?
              <tbody><tr><td colSpan={'2'}><Divider className={styles.divider} /></td></tr></tbody> : false}

            {generalSpecs && generalSpecs.length ?
              <ErrorBoundary>
                <thead>
                  <tr>
                    <Typography as={'th'} font={'heading'} className={styles.spec_heading}>
                      GENERAL
                    </Typography>
                  </tr>
                </thead>
                <Table specs={generalSpecs} indexOptions={indexOptions} />
              </ErrorBoundary> : false}
          </table>
        </ErrorBoundary>
      </Accordion>
    </Card>
  )
}

export default Specs